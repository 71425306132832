import React from 'react';
import Socials from './Socials';

export default function Footer () {
  return (
    <footer className='footer'>
    <Socials/>
    <p className='footer__copyright'>© 2024 Dehayez</p>
  </footer>
  )
}