import './App.scss';
import * as React from 'react';
import { Content, Header, Footer } from './components';

function App() {

  return (
      <div className="base">
        <Header/>
        <Content/>
        <Footer/>
      </div>
  );
}

export default App;
