import React from 'react';
import Logo from './Logo';

export default function Header() {
  return (
    <header className='navbar'>
        <ul className='navbar__list'>
        <li className='navbar__list-item navbar__list-item--left'></li>

        <li className='navbar__list-item navbar__list-item navbar__list-item--middle'>
            <a className='list-item__link' href="/">
            <Logo/>
            </a>
        </li>

        <li className='navbar__list-item navbar__list-item--right'></li>
        </ul>
    </header>
  )
}
