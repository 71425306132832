import React from 'react';
import instagram from '../images/icons/instagram.svg';
import soundcloud from '../images/icons/soundcloud.svg';

export default function Socials() {
    const socials = [
      {
        title: 'Instagram',
        slug: 'instagram',
        svg: instagram,
        href:'https://www.instagram.com/dehayez/', 
        id: 1
      },
      {
        title: 'Soundcloud',
        slug: 'soundcloud',
        svg: soundcloud,
        href:'https://soundcloud.com/dehayez', 
        id: 2
      },
    ];
    
    const socialItems = socials.map(socials =>
        <a  className={`social-item social-item--${socials.slug}`} key={socials.id} href={socials.href} title={socials.title} target="_blank" rel="noopener noreferrer">
          <img className='social-item__image' fill='currentColor' src={socials.svg} alt={socials.title}></img>
        </a>
    );
    return (
        <div className='social-items'>{socialItems}</div>
    )
}